import React from "react";
import { Container, Row, Col } from "reactstrap";

import chooseImg from "../../assests/images/hero-img1.png";
import "./choose-us.css";


const ChooseUs = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="choose__content">
              <h2>Why Choose Us</h2>
              <p>
                SkillLevel is an Ed-tech platform which provides Training Certification and Internship Opportunities. The motive behind starting SkillLevel was to make high-quality education accessible to everyone and bridge the gap between urban and rural students and help acheiving the dreams.<br></br> We strive to provide comprehensive, user-friendly, and cost-effective solutions that enhance learning outcomes and enable personal growth. Industry Experts will always be there at every possible point in your learning and achieving journey.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="choose__img">
                <img src={chooseImg} alt="" className="w-100" style={{borderRadius: "40px",boxShadow:"2px 10px 5px 5px"}} />   
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUs;
