import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./header.css";

function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" style={{backgroundColor:"rgba(226, 248, 248, 0.664)"}}>
      <Container className='nav'>
        <Navbar.Brand> <h3 style={{font:"bold"}} className="animate-charcter">SkillLevel</h3></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <NavDropdown title="Courses" id="basic-nav-dropdown">
              <NavDropdown.Item href="/singlepack" style={{color: "red"}}>Single Pack</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/businesspack" style={{color: "green"}}>Business Pack</NavDropdown.Item>
              <NavDropdown.Item href="/technicalpack" style={{color: "green"}}>
                Technical Pack
              </NavDropdown.Item>
              <NavDropdown.Item href="/creatorpack" style={{color: "green"}}>
                Creator Pack
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/allinone" style={{color: "orange"}}>
                All in one pack
              </NavDropdown.Item>
            </NavDropdown> */}

            <NavDropdown title="Certification Workshop" id="basic-nav-dropdown">
              <NavDropdown.Item href="/technicalpack">Tech Stack</NavDropdown.Item>
              <NavDropdown.Item href="/businesspack">
                Business Plan
              </NavDropdown.Item>
              <NavDropdown.Item href="/creatorpack">
                Creators World
              </NavDropdown.Item>
              <NavDropdown.Item href="/psychology">
              Psychology
              </NavDropdown.Item>
            </NavDropdown>
            
            <NavDropdown title="Placement Readiness" id="basic-nav-dropdown">
              <NavDropdown.Item href="/placementsupport">Prepare for all major Company</NavDropdown.Item>
              <NavDropdown.Item href="/last" style={{color: "red"}}>Final 15 Days Crash Course</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Career Assistance" id="basic-nav-dropdown">
              <NavDropdown.Item href="/internship">Career Opportunity</NavDropdown.Item>
              <NavDropdown.Item href="/support" target='blank'>Support Panel</NavDropdown.Item>
              <NavDropdown.Item href="/admission">Admission Consultation</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;