import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/anant.png";
import "./hero-section.css";

const HeroSection = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 hero__title">
                Empowering minds<br /> Learn, grow, upskill with us<br /> 

                
                <div class="slide-effect">
  <h6 class="text">Everthing at</h6>
  <h6 class="slideDown">Just One</h6>
  <h6 class="slideUp">Place</h6>
</div>
              </h2>
              <p className="mb-5">
                SkillLevel is dedicated to fostering ongoing discussions on leveraging technology for educational transformation, integrating modern skills, and fostering partnerships with industrial experts. Our diverse audience spans students, educators, professionals and innovators. Through research and training workshops, we aim to advance EdTech globally, democratizing education for all backgrounds.
              </p>
            </div>
            
          </Col>
        
          <Col lg="6" md="6">
          <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
