import Home from "./pages/Home";
import "././pages/App.css";

function App() {
  return (
  <div>
    <Home />
    <a
        href="https://wa.me/9234988265?text=Hi please tell more about your program?"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
  </div>
  );
}

export default App;
