import Header from '../../Header/Header';
import Footer from '../Footer';
import { Container } from 'react-bootstrap';

function Terms() {
  return (
    <>
    <Header/>
    <Container style={{background: "radial-gradient(circle, #3CB371, white)",borderRadius:"10px"}}>
   <center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>Terms & Conditions</h2> </center>
<p>The terms 'We'/'Us'/'Our'/'Company' individually and collectively refer to Institution 1, 
while 'Visitor'/'User' refers to users. This page outlines the Terms and Conditions for 
visiting this website ('SkillLevel'). Please review this page carefully. If you do not agree 
to these Terms and Conditions, we kindly ask you to exit the site. The business, its 
divisions, subsidiaries, associate companies, or investment companies reserve the right 
to revise these Terms and Conditions by updating this posting. It is advised to 
periodically review this page, as the Terms and Conditions are binding for all users. </p>


<center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>USE OF CONTENT</h2> </center>
<p>All content on this site, including logos, brands, marks, headings, labels, names, 
signatures, numerals, shapes, or any combinations thereof, are properties of the 
business or its associate entities. Unauthorized use of this content is strictly prohibited, 
except as permitted in these terms and conditions. </p>




     
<center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>ACCEPTABLE WEBSITE USE</h2> </center>
<p>
    <ul>
        <li>Security Rules: Visitors are prohibited from attempting to violate the security of the 
website, including unauthorized access, probing, scanning, or testing vulnerabilities, or 
interfering with service to users, hosts, or networks</li>
<li> General Rules: Visitors may not use the website to transmit material that could 
constitute a criminal offense, infringe intellectual property rights, or violate personal 
rights of others. 
</li>
    </ul>
     </p>



     
<center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>INDEMNITY</h2> </center>
<p>
Users agree to indemnify and hold the Company, its officers, directors, employees, and 
agents harmless from any claims arising from their use of the website or breach of 
these terms. 
     </p>



     
<center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>LIABILITY</h2> </center>
<p>
The Company, its group companies, directors, officers, or employees shall not be liable 
for any damages resulting from the use of the service, including interruption, 
SkillLevel 
support@skilllevel.in 
suspension, or termination of service. The Company's total liability for all damages shall 
not exceed the amount paid by the User to the Company. 
     </p>



     
<center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>DISCLAIMER OF CONSEQUENTIAL DAMAGES</h2> </center>
<p>
The Company and associated entities shall not be liable for any damages resulting from 
the use or inability to use the Website, whether based on warranty, contract, tort, or any 
other legal theory, even if advised of the possibility of such damages. 
     </p>



     
<center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>PAYMENTS 
</h2> </center>
<p>
The Platform operates as a subscription-based service, and the fees associated with 
the plans chosen by You shall be in accordance with Your selections made through the 
Platform. By using the Platform, You expressly agree to pay the fees for the 
courses/plans You purchase, and You authorize the Company to charge You using 
Your chosen payment method. All payments must be made through the payment 
mechanism provided by the Company, and You are responsible for paying all fees and 
applicable taxes promptly as outlined in the chosen plan. Payment options include Card 
Payment facilities, Online Bank Transfer, and Wallet Payment, among others. Users 
agree to abide by the terms and conditions of the third-party payment providers listed on 
the Platform. Credit/Debit/Cash Card/online bank transfers and other payment 
instruments are processed through appropriate payment system infrastructure, 
governed by the terms agreed upon between Users and their respective issuing banks 
and payment instrument providers. We are not liable for unauthorized use, fraud, 
payment refunds, or lost amounts in transactions. We do not offer refunds for 
workshops conducted and reserve the right to change fees at our discretion, with such 
changes becoming effective immediately upon posting on our website.
     </p>

     <center> <h2 style={{marginTop:"1rem",marginBottom:"1rem"}}>Refund Policy 
</h2> </center>
<p>
We do not offer refunds for Courses provided by SkillLevel. All payments are final and 
non-refundable once made. Users are encouraged to review the comprehensive 
information provided for the Course before proceeding with payment. Our no-refund 
policy is accepted by making payments, along with other terms and conditions specified 
on the website/platforms.
     </p>


</Container>
  <Footer/>
  </>
  );
}

export default Terms;