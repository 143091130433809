import 'bootstrap/dist/css/bootstrap.min.css';  
import { Card, Button} from 'react-bootstrap'; 
import ceo from "../../assests/images/ceo.jpg";
import cto from "../../assests/images/cto.jpg";
//import cmo from "../../assests/images/cmo.jpg";
import './testimonial.css';

function Testimonials() {  
  return (  
    
    <div id="c" className='d-flex flex-row row justify-content-md-center row row-lg-2'>   
 <center><h2 style={{marginBottom:"1em",fontWeight:"600"}}>Meet Our Founders</h2></center>
  <Card style={{ width: "22rem",marginRight:"20px",borderRadius:"200px!important"}} className="shadow-lg p-3 mb-5 bg-grey rounded border border-success">  
  <Card.Img variant="top" src={ceo} style={{borderRadius:"20px"}}/>  
  <Card.Body>  
    <Card.Title><center style={{fontWeight:"700"}}>Anant Kumar (CEO)</center></Card.Title>  
    <Card.Text>  
    SkillLevel was founded with a vision to transform education using technology, empowering learners worldwide to reach their full potential regardless of their backgrounds or circumstances. 
    </Card.Text>  
   <center> <Button variant="primary" href="https://www.linkedin.com/in/anant-kumar-000277184?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="blank">Connect on Linkedin</Button>  </center>
  </Card.Body>  
</Card>   


     
  {/* <Card style={{ width: "22rem",marginRight:"20px"}} className="shadow-lg p-3 mb-5 bg-grey rounded border border-success">  
  <Card.Img variant="top" src={cmo} style={{borderRadius:"20px"}}/>  
  <Card.Body>  
    <Card.Title><center style={{fontWeight:"700"}}>Anurag Raj (COO)</center></Card.Title>  
    <Card.Text>  
    I am filled with immense pride as I reflect on the journey we have embarked upon. SkillLevel was born from a profound passion for revolutionizing education through the integration of technology.
    </Card.Text>  
   <center> <Button variant="primary" href="https://www.linkedin.com/in/anurag-raj-458364244?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="blank" >Connect on Linkedin</Button>  </center>
  </Card.Body>  
</Card>   */}
 



  
  <Card style={{ width: "22rem",marginRight:"20px"}} className="shadow-lg p-3 mb-5 bg-grey rounded border border-success">  
  <Card.Img variant="top" src={cto} style={{borderRadius:"20px"}}/>  
  <Card.Body>  
    <Card.Title><center style={{fontWeight:"700"}}>Saurabh Trivedi (CTO&MD)</center></Card.Title>  
    <Card.Text>  
    Continuous innovation drives our approach at SkillLevel. We harness advancements in education to enhance our platform, offering dynamic learning experiences guided by industry experts.
    </Card.Text>  
   <center> <Button variant="primary" href="https://www.linkedin.com/in/saurabh-trivedi-a12b5b1a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="blank">Connect on Linkedin</Button> </center>  
  </Card.Body>  
</Card>  
 
    </div> 

  );  
}  
export default Testimonials;  


