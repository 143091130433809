import React,{Suspense,lazy} from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Terms from "./components/Footer/Terms/Terms";
const TechnicalPack=lazy(()=>import("./components/Courses/TechnicalPack/TechnicalPack"));
const BusinessPack = lazy(()=>import("./components/Courses/BusinessPack/BusinessPack"));
const CreatorPack = lazy(()=>import("./components/Courses/CreatorPack/CreatorPack"));
const PlacementSupport = lazy(()=>import("./components/Courses/PlacementSupport/PlacementSupport"));
const LastMinute = lazy(()=>import("./components/Courses/LastMinute/LastMinute"));
const ThankYou = lazy(()=>import("./components/ThankYou/ThankYou"));
const Support = lazy(()=>import("./components/Support/Support"));
const Admission = lazy(()=>import("./components/Admission/Admission"));
const Psychology = lazy(()=>import("./components/Courses/Psychology/Psychology"));
const Internship = lazy(()=>import("./components/Courses/InternshipOpp/InternshipOpp"));
const Faq = lazy(()=>import("./components/Footer/Faq/Faq"));
const Policy = lazy(()=>import("./components/Footer/Policy/Policy"));


const router = createBrowserRouter([
  {
    path: "/",
    element: <App></App>,
  },
  {
    path:"/technicalpack",
    element:<Suspense fallback={<div className="loader"></div>}><TechnicalPack/></Suspense>,
  },
  {
    path:"/creatorPack",
    element:<Suspense fallback={<div className="loader"></div>}><CreatorPack/></Suspense>
  },
  {
    path:"/businesspack",
    element:<Suspense fallback={<div className="loader"></div>}><BusinessPack/></Suspense>
  },
  {
    path:"/placementsupport",
    element:<Suspense fallback={<div className="loader"></div>}><PlacementSupport/></Suspense>
  },
  {
    path:"/last",
    element:<Suspense fallback={<div className="loader"></div>}><LastMinute/></Suspense>
  },
  {
    path:"/internship",
    element:<Suspense fallback={<div className="loader"></div>}><Internship/></Suspense>
  },
  {
    path:"/faq",
    element:<Suspense fallback={<div className="loader"></div>}><Faq/></Suspense>
  },
  {
    path:"/admission",
    element:<Suspense fallback={<div className="loader"></div>}><Admission/></Suspense>
  },
  {
    path:"/support",
    element:<Suspense fallback={<div className="loader"></div>}><Support/></Suspense>
  },
  {
    path:"/psychology",
    element:<Suspense fallback={<div className="loader"></div>}><Psychology/></Suspense>
  },
  {
    path:"/thankyou",
    element:<Suspense fallback={<div className="loader"></div>}><ThankYou/></Suspense>
  },
  {
    path:"/*",
    element:<App></App>
  },
  {
    path:"/terms",
    element:<Suspense fallback={<div className="loader"></div>}><Terms/></Suspense>
  },
  {
    path:"/policy",
    element:<Suspense fallback={<div className="loader"></div>}><Policy></Policy></Suspense>
  }

]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>,
  document.getElementById("root")
);
