import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./company-section.css";

const Company = () => {
  return (
    <section>
      <h2 className="center">Some of the top firms our alumni and our educator works at.</h2>
      <Container>
      <div className="marquee">
        <Row className="marquee__inner">
          <Col lg="2" md="3" sm="4" xs="6">
            <h3 className=" d-flex align-items-center gap-1">
            <i class="ri-amazon-fill"></i> Amazon
            </h3>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h3 className=" d-flex align-items-center gap-1">
              <i class="ri-pinterest-line"></i> Pinterest
            </h3>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h3 className=" d-flex align-items-center gap-1">
            <i class="ri-paypal-line"></i> Paypal
            </h3>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h3 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-linkedin-fill"></i> Linkedin
            </h3>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h3 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-microsoft-fill"></i> Microsoft
            </h3>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h2 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-send-plane-2-fill" style={{transform: "rotate(270deg)"}}></i> Adobe
            </h2>
          </Col>

          
          <Col lg="2" md="3" sm="4" xs="6">
            <h2 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-visa-line"></i> Visa
            </h2>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h2 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-bank-fill"></i> Barclays
            </h2>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h2 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="fa fa-shopping-cart"></i>Flipkart
            </h2>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h2 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-newspaper-line"></i>Aaj Tak
              
            </h2>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h2 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-hotel-line"></i> Intel
            </h2>
          </Col>

          <Col lg="2" md="3" sm="4" xs="6">
            <h2 className=" d-flex align-items-center gap-1">
              {" "}
              <i class="ri-building-fill"></i>And...
            </h2>
          </Col>
  
          
        </Row>
        </div>
      </Container>
    </section>
  );
};

export default Company;
